import React from "react"
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-modal-routing-3'
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Mainnav from "../components/mainnav"
import Logo from "../components/logo"
import {Helmet} from "react-helmet"
import Current from "../components/current"
import Actions from "../components/actions"
import About from "../components/about"
import Anisection from '../components/anisection'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Marquee from "react-fast-marquee"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const Mainwrap = styled.main`
section {
        padding: 10vh 0;
        width: 100vw;
        background-attachment: fixed;
        background-size: cover;
        background-position-x: center;   
}    
    button {
      background: transparent;
      border: 0;
      padding: 0;
      color: rgba(255,255,255,.66);
      position: absolute;
      z-index: 7;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      &:hover {
        cursor: pointer;
        color: rgba(255,255,255,1);
      }
    }
    .slide a img {
      transition: all 0.3s ease;
    }
    .slide a:hover img {
      filter: brightness(33%);
    }
    a video {
      transition: all 0.3s ease;
      &:hover {
        opacity: 0.75;
      }
    }
`
const Inquiries = styled.section`
width: 100vw;
padding: 0 !important;
.simplebar-scrollbar::before {
    background-color: rgba(255,255,255,0.33);
}
> div {
    height: 85vh;
    min-width: 90vw;
    padding: 0 5vw !important;
    box-sizing: border-box;
    background-color: #000;
}
section {
    display: flex;
    align-items: top;
    flex-wrap: nowrap;
    padding: inherit;
    width: inherit;
    margin-top: 22.5vh;
}
section > div {
    margin-right: 6vw;
    flex: 0 0 auto;
    padding: 20px;
    background-color: rgba(255,255,255,0.1);
    transition: all 0.3s ease;
    &:hover {
        background-color: rgba(255,255,255,0.9);
    }
}
section > div:last-of-type {
    margin-right: 0;
}
section > div a {
    color: rgba(255,255,255,0.66);
    transition: all 0.3s ease;
    &:hover {
        color: #000;
    }
}
section > div a > div {
    position: relative;
  }
  .gatsby-image-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }
  img {
      padding: 0 !important;
      height: 30vh;
      width: 30vh;
      @media (max-width: 599px) {
        height: 27.5vh;
        width: 27.5vh;
      }
  }
  h4 {
    margin-bottom: 0;
    margin-top: 3vh;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    width: 30vh;
    font-family: courier;
    text-transform: uppercase;
    line-height: 1.25em;
    letter-spacing: .5px;
    @media (max-width: 599px) {
      width: 27.5vh;
    }
}  
`

export default function Home({ data }) {
  return (
    <>
    <Seo />
    <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;500;600;700&family=Texturina:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet" />
        <script
          src="https://code.jquery.com/jquery-3.4.1.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
          crossOrigin="anonymous">
        </script>
    </Helmet>
    <Logo />
    <Mainnav />
    <Mainwrap>
    {/* <Marquee style={{position: 'absolute', width: '100%', transform: 'rotate(-5deg)', zIndex: '3', fontFamily: 'Texturina', fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: '100', letterSpacing: '15px'}} gradient={false} ><a href="https://prepostness.designinquiry.net">Prepostness June 20–26, 2021 Vinalhaven, Maine</a></Marquee> */}
    <Current />
    {/* <Actions /> */}
    <Inquiries>
    <Anisection>
    <SimpleBar style={{ maxWidth: "90vw"}}>
            <section>
            {data.allMdx.edges.map(({node}) => (
                <div key={node.id}><Link to={`/${node.slug}`} asModal><div><GatsbyImage image={node.frontmatter.ledeimage.childImageSharp.gatsbyImageData} alt="image" objectFit="conver" /><h4>{node.frontmatter.title}</h4></div></Link></div>
            ))}
            </section>
    </SimpleBar>
    </Anisection>
    </Inquiries>
    <About />
    </Mainwrap>
    </>
  )
}

export const query = graphql`
query {
    allMdx(
      filter: {frontmatter: {section: {eq: "Residencies"}}}
      sort: {order: DESC, fields: frontmatter___date}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            ledeimage {
              childImageSharp {
                gatsbyImageData
              }
            }      
          }
          slug
        }
      }
    }
  }   

`
